import { abortNavigation, defineNuxtRouteMiddleware, useNuxtApp } from 'nuxt/app';
import { storeToRefs } from 'pinia';
import type { RouteLocationNormalized } from 'vue-router';

import { showAlert } from '@/composables/useDialog';
import { PRODUCT_ACCESS_DENY_ERROR_CODE } from '@/constants/error.const';
import { ERROR_PAGE_NAME } from '@/constants/url.const';
import useProductStore from '@/stores/product.store';
import { isDirectAccess, redirectTo } from '@/utils/common.util';

export default defineNuxtRouteMiddleware(
  async (to: RouteLocationNormalized, from: RouteLocationNormalized) => {
    const { name: toName } = to;
    const { name: fromName } = from;
    const nuxtApp = useNuxtApp();
    const t = (nuxtApp.$i18n as any)?.t;

    const productStore = useProductStore();
    const { isProductWritable } = storeToRefs(productStore);

    if (isProductWritable.value) {
      return;
    }

    const msg = 'studio.common.popup_case_h_not_edit';

    if (isDirectAccess(toName, fromName)) {
      return await redirectTo(ERROR_PAGE_NAME, {
        toType: 'name',
        query: { code: PRODUCT_ACCESS_DENY_ERROR_CODE }
      });
    }
    await showAlert({ content: t(msg) });
    return abortNavigation();
  }
);
